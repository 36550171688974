export class LemmaWord {
  word: string;
  translate: string;

  constructor(word?: string, translate?: string) {
    this.word = word;
    this.translate = translate;
  }

}
